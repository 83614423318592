import React from 'react'
import './About.css'
import Typewriter from 'typewriter-effect'
import { Link } from 'react-scroll'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import Skills from '../skills/Skills'

const About = () => {
  return (
    <Section title="Sobre">
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">
            <h2>Um pouco sobre mim</h2>
            <p>
              Meu nome é Jarder{' '}
              <span role="img" aria-label="lightning">
                😄
              </span>{' '}
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Eu sou programador full-stack, desenvolvendo aplicações web, mobile, desktop como também soluções para back-end, micro serviços, API, e etc.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              Tenho o objetivo de evoluir meu inglês e me tornar um profissional capaz de lidar com as mudanças do mundo.
            </p>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                Eu gosto
              </p>
              <Typewriter
                options={{
                  strings: [
                    'aprender novas tecnologias',
                    'projetar coisas',
                    'trabalhar com pessoas',
                    'ajudar meus colegas de trabalhos',
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
            <p>
              Minha carreira de programador começou em 2016, quando eu ainda estava na faculdade,
              desde então eu estou continuamente aprendendo novas tecnologias
              afim de me tornar um profissional capaz de lidar com as mudanças do mundo.
            </p>
            <div className="location-wrapper">
              <svg
                className="octicon octicon-location"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
                ></path>
              </svg>
              <p>Balsas, Ma, Brasil</p>
            </div>
          </div>
        </Fade>
        <Skills />
      </div>
    </Section>
  )
}

export default About
